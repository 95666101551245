document.addEventListener('turbolinks:load', function() {
  if(window.ga && ga.loaded) {
    gtag('config', 'UA-74187457-1')
  }
});

document.addEventListener("turbolinks:load", function(event) {

    let tab = document.getElementsByClassName('tab');

    //scrolling for category mob
    const anchorMob = document.getElementsByClassName("scroll-top")[0];

    if(anchorMob) anchorMob.onclick = scrollToTop;

    function scrollToTop() {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        })
    }

    if (tab.length){

        //tabs
        let tabContent = document.getElementsByClassName('tabContent');

        hideTabsContent(1);

        document.getElementsByClassName('main-tabs')[0].onclick = function (event) {
            let target = event.target;
            // console.log(event.target.innerText)
            if (target.className == 'tab') {
            for (let i = 0; i < tab.length; i++) {
                if (target == tab[i]) {
                    showTabsContent(i);
                    break;
                }
            }
            }
        }

        function hideTabsContent(a) {
            for (let i = a; i < tabContent.length; i++) {
                tabContent[i].classList.remove('show');
                tabContent[i].classList.add("hide");
                tab[i].classList.remove('tab-active');
            }
        }

        function showTabsContent(b){
            if (tabContent[b].classList.contains('hide')) {
                hideTabsContent(0);
                tab[b].classList.add('tab-active');
                tabContent[b].classList.remove('hide');
                tabContent[b].classList.add('show');
            }
        }


        //toggleSlide list
        const toggleBtn = document.getElementsByClassName("toggle-list");

        for(let i = 0; i < toggleBtn.length; i++){
            toggleBtn[i].addEventListener("click",function(){
                let toggledList = this.previousElementSibling;
                toggledList.classList.toggle("static-list-open");
                if(this.classList.contains("toggle-list-open")) {
                    this.innerHTML = "Весь список";
                } else {
                  if (window.location.href.includes('/ua/')){
                    this.innerHTML = "Згорнути список";

                  } else {
                    this.innerHTML = "Свернуть список";
                  }

                }
                this.classList.toggle("toggle-list-open");
            })
        }

        //lightbox
        let slideIndex = 1;
        let header = document.getElementsByClassName("header")[0];
        let lightBoxDimmer = document.getElementById("Lightbox");
        let miniSlides = document.getElementsByClassName("col");
        let closeLightBox = document.getElementsByClassName("lightbox-preview-close")[0];
        let prevSlide = document.getElementsByClassName("previous")[0];
        let nextSlide = document.getElementsByClassName("next")[0];

        prevSlide.onclick = () => changeSlide(-1);
        nextSlide.onclick = () => changeSlide(1);
        closeLightBox.onclick = closeLightbox;

		for(let i = 0; i < miniSlides.length; i++) {

            let value = miniSlides[i].dataset.value - 0;

            miniSlides[i].addEventListener("click",function(){
                openLightbox();
                toSlide(value);
            })
        }

        showSlide(slideIndex);

        lightBoxDimmer.addEventListener('click', function(e) {
            if (e.target === this) {
                closeLightbox()
            }
        })

        function openLightbox() {
            document.getElementById('Lightbox').style.display = 'flex';
            header.style.zIndex = "0";
            document.getElementsByTagName("footer")[0].style.zIndex = "0";
            document.getElementsByClassName("main-tabs-labels")[0].style.zIndex = "0";
        }

        function closeLightbox() {
            document.getElementById('Lightbox').style.display = 'none';
            header.style.zIndex = "10";
            document.getElementsByTagName("footer")[0].style.zIndex = "1";
            document.getElementsByClassName("main-tabs-labels")[0].style.zIndex = "0";
        }

        function changeSlide(n) {
            showSlide(slideIndex += n);
        }

        function toSlide(n) {
            showSlide(slideIndex = n);
        }

        function showSlide(n) {

            const slides = document.getElementsByClassName('slide');

            if (n > slides.length) {
                slideIndex = 1;
            }

            if (n < 1) {
                slideIndex = slides.length;
            }

            for (let i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }

            slides[slideIndex - 1].style.display = 'block';
        }
    }

    //change text in next and prev button
    const prev = document.getElementsByClassName("info-change-prev")[0];
    const next = document.getElementsByClassName("info-change-next")[0];

    if (window.innerWidth < 1024) {
        prev.innerHTML = "Пред.";
        next.innerHTML = "Cлед.";
    }
});
